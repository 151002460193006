
.selectedContact {
    background: #a0a0a0 !important;
    color: #fefefe !important;
    box-shadow: 0 0 7px 3px #002b63;
    z-index: 10;
}

.searchResultBlock {
    padding: 2rem;
}


.searchResultItem {
    border: 1px solid #c7ddec;
    border-radius: 5px;
    background:#edf4f9;
    color: #002b63;
    padding: .25rem 2rem;
    transition: background 0.3s, border 0.3s, box-shadow 0.3s;
}

.searchResultItem:hover {
    cursor: pointer;
    background: #c7ddec;
}