.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 10px;
    background: #61dafb;
    border-radius: 5px;
    animation: load 1.8s ease-in-out infinite; 
  }
  
  .loader:before, .loader:after {
      position: absolute; 
      display: block;
      content: "";
      animation: load 1.8s ease-in-out infinite;
      height: 10px;
      border-radius: 5px;
  }
  
  .loader:before {
      top: -20px;
      left: 10px;
      width: 40px;
      background: #00338d;
  }
  
  .loader:after {
      bottom: -20px;
      width: 35px;
      background: #4c5680; 
  }
  
  @keyframes load {
    0% {
      transform: translateX(40px);
    }
    
    50% {
      transform: translateX(-30px);
    }
    100% {
      transform: translateX(40px);
    }
  }